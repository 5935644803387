<template lang="pug">
.landing-page__block
  .title-block
    span.title Широкий выбор фильтров для поиска тендеров
    span.subtitle Быстрый поиск по ключевым словам и отраслям, расширенный поиск по цене, площадкам, законам, и прочим фильтрам
  img.img1(src="/pics/landing/search-form.png")
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "AboutSearch",
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";

img {
  @include system-image;
  max-width: var(--main-content-width);
}
</style>
