<template lang="pug">
.landing-page__block
  .title-block
    .title Наглядная тендерная аналитика
    .subtitle Изучите все нюансы рынка, проведите полный анализ для принятия решения участия в торгах.
  .about-system
    .info._right
      span.main Анализ поставщиков и конкурентов
      span Изучите поставщиков и конкурентов, проанализируйте закупки, в которых они участвуют
      span Посмотрите, что говорит статистика: подробные графики по количеству и сумме участий, группировка по годам и регионам
    .images
      img.img1(src="/pics/landing/analytic-customer.png")
      img.img2(src="/pics/landing/analytic-statistic.png")
  .about-system._reverse
    .info._left
      span.main Анализ закупок
      span Изучите актуальные и завершенные закупки заказчиков
      span Изучите победы и участия поставщиков, их заказчиков и конкурентов
      span Изучите закупки: их начальную цену, процент снижения цены, количество участников
    .images
      img.img3(src="/pics/landing/analytic-customer-actual-2.png")
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "AboutAnalytic",
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";

.landing-page__block {
  gap: 32px;
}

.about-system {
  &._reverse {
    justify-content: end;
  }

  .info {
    &._left {
      left: 0;
      z-index: 10;
      background-color: #ffd9d9;
    }

    &._right {
      bottom: 0;
      right: 0;
      z-index: 10;
    }
  }

  .images {
    .img2 {
      width: 100%;
      max-width: 700px;
      z-index: 1;
    }

    .img1 {
      width: 70%;
      max-width: 520px;
      position: absolute;
      top: -74px;
      z-index: 2;
      right: 20%;
    }

    .img3 {
      width: 100%;
      max-width: 780px;
      z-index: 2;
    }
  }
}

@media (max-width: 640px) {
  .about-system {
    .images {
      .img1 {
        bottom: 80px;
        right: 20px;
        top: unset;
      }
    }
  }
}
</style>
