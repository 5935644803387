<template lang="pug">
.main-section
  span.description Система управления тендерами
  span.company-label ТЕНДЕРМОНИТОР
  .landing-page__main-section
    .landing-page__main-section__text
      span.description Все тендеры и госзакупки России и Казахстана {{ getYear() }}
      span.main
        b ТендерМонитор
        | - Сервис поиска государственных и коммерческих закупок, тендеров по № 44-ФЗ, № 223-ФЗ, ПП-615
      span(style="color: #F56C6C;") Акция - скидка 40% на годовой тариф!*
      .actions
        ui-button.register-button(@click="showDialog('registration')") Попробуй 7 дней бесплатно
        ui-button.promo-button(@click="showTariff") Подробности акции
      span.promo-agreement * Акция доступна только новым пользователям при покупке тарифа "Я - Специалист" на 1 год. Сроки акции 13.11.2024 - 30.11.2024.
    img.widget-image(src="/pics/landing/main-pic.png")
    .shadow
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useLandingLogin } from "@/use/auth/useLandingLogin";
import { getYear } from "@/utils/formatter/dateFormatter";
import { scrollIntoView } from "~/utils/scroller/documentScroller";

import UiButton from "@/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "MainSection",
  methods: {
    getYear,
  },
  components: {
    UiButton,
  },
  setup() {

    const { showDialog } = useLandingLogin();

    function showTariff() {
      scrollIntoView('tariffs', 'start')
    }

    return {
      showTariff,
      showDialog,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/button';

.actions {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}

.main-section {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 120px 32px 64px 96px;

  .company-label {
    color: #00B8FF;
    font-size: 72px;
    font-weight: bold;
    letter-spacing: 0.7px;
    margin: 12px 0;
    z-index: 1;
  }

  .description {
    color: #324059;
    font-size: 26px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 0;
    z-index: 1;
  }

  .shadow {
    width: 0;
    height: 0;
    position: absolute;
    top: 380px;
    right: 300px;
    box-shadow:
      -50px 150px 100px 200px rgba(0, 120, 255, 0.32),
      -340px 200px 100px 200px rgb(255 0 0 / 17%);
  }
}

.label-image {
  max-width: 80%;
  z-index: 1;
}

.widget-image {
  position: relative;
  z-index: 1;
  width: 70%;
  max-width: 800px;
  height: auto;
}

.landing-page__main-section {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.landing-page__main-section__text {
  display: flex;
  flex-flow: column;
  gap: 32px;
  z-index: 1;

  .description {
    color: #324059;
    font-size: 22px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: 0;
    z-index: 1;
  }

  .main {
    color: #324059;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    z-index: 1;

    b {
      color: #00B8FF;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0.7px;
      margin-right: 8px;
    }
  }
}

.promo-agreement {
  font-size: 12px;
}

@media (max-width: 1000px) {
  .widget-image {
    width: 50%;
    max-width: 600px;
  }

  .main-section {
    padding: 90px 32px 32px 48px;
    .company-label { font-size: 48px; }
    .description { font-size: 20px; }
    .shadow {
      box-shadow:
        70px 50px 100px 100px rgba(0, 120, 255, 0.32),
        -100px 200px 100px 100px rgba(255, 0, 0, 0.17);
    }
  }

  .landing-page__main-section__text {
    .description { font-size: 20px; }
    .main {
      font-size: 18px;
      b {
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 860px) {
  .widget-image {
    width: 50%;
    max-width: 360px;
    min-width: 240px;
  }

  .main-section {
    .description { font-size: 18px; }
    .shadow {
      box-shadow:
        200px -50px 100px 100px rgba(0, 120, 255, 0.32),
        -10px 20px 100px 100px rgba(255, 0, 0, 0.17);
    }
  }

  .landing-page__main-section__text {
    .description {
      font-size: 18px;
      line-height: 24px;
    }
    .main {
      font-size: 16px;
      line-height: 24px;

      b {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 640px) {

  .landing-page__main-section {
    flex-flow: column;
    gap: 32px;
  }

  .main-section {
    .company-label { font-size: 28px; }
    .description { font-size: 16px; }
    .shadow {
      box-shadow:
        140px 50px 100px 100px rgba(0, 120, 255, 0.32),
        -80px 200px 100px 100px rgba(255, 0, 0, 0.17);
    }
  }

  .landing-page__main-section__text {
    gap: 16px;

    .description { font-size: 16px; }
    .main { font-size: 16px; }
  }
}
</style>
